import axios from 'axios';

export default {
  items: {
    get({ id, ...params }) {
      if (id) {
        return axios.get(`/v1/approvals-app/items/${id}`, { params });
      }
    },
    put({ id, ...params }) {
      if (id) {
        return axios.put(`/v1/approvals-app/items/${id}`, params);
      }
      return axios.put('/v1/approvals-app/items/', params);
    },
  },
};
