<template>
  <v-container>
    <v-row>
      <v-col v-if="status === 'requested'">
        <ValidationObserver
          ref="form_approval"
          tag="form"
          @submit="handleSubmit"
          id="form_approval"
        >
          <div style="max-width: 600px; margin: 0 auto;">
            <v-card elevation="1" :loading="loading">
              <v-card-text>
                <v-row v-for="(item, index) in jsonSchema" :key="`item_${index}`">
                  <v-col>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <v-checkbox v-model="item.checked" :error-messages="errors" hide-details :disabled="loading">
                        <template v-slot:label>
                          <div v-html="replaceHTML({ html: item.html })"></div>
                        </template>
                      </v-checkbox>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" x-large @click="handleSubmit" :disabled="loading">Accept</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </ValidationObserver>
      </v-col>
      <v-col v-if="status === 'approved'">
        <div style="max-width: 600px; margin: 0 auto;">
          <v-card elevation="1">
            <p style="text-align: center; padding: 3rem 0;">Thank you</p>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import api from '@/api';

export default {
  name: 'Approval',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      jsonSchema: [],
      status: null,
      loading: false,
    };
  },
  methods: {
    getItems({ id }) {
      api.items.get({ id }).then((response) => {
        const { items } = response.data;
        if (items.length > 0) {
          this.jsonSchema = items[0].json_schema;
          this.status = items[0].status;
        }
        setTimeout(function() {
          this.setEventListeners();
        }.bind(this), 500);
      });
    },
    replaceHTML({ html }) {
      if (html.length > 0) {
        return html.replace(/<a/ig, '<a class="checkbox-link" ');
      }
      return '';
    },
    setEventListeners() {
      const items = document.querySelectorAll('.checkbox-link');
      items.forEach(item => {
        item.addEventListener('click', function handleClick(event) {
          event.stopImmediatePropagation();
        });
      });
    },
    async handleSubmit() {
      const isValid = await this.$refs.form_approval.validate();

      if (isValid) {
        const { id } = this.$route.params;
        this.loading = true;
        api.items.put({ id, status: 'approved' }).then(() => {
          this.status = 'approved';
          this.loading = false;
        });
      }
    },
  },
  watch: {
    '$route.params.id': {
      handler: function (newVal, oldVal) {
        if (newVal && (newVal !== oldVal)) {
          this.getItems({ id: newVal });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
